<template>
  <div>
    <!-- Filter -->
    <div class="row align-items-center justify-content-start mt-n3">
      <div class="col-md-8 col-12">
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" @click="btnActiveOnClick('days')" v-bind:class="{ 'btn-info': btnActive.days, 'btn-outline-info': btnActive.days == false }">Hari Ini</button>
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" @click="btnActiveOnClick('month')" v-bind:class="{ 'btn-info': btnActive.month, 'btn-outline-info': btnActive.month == false }">Bulan Ini</button>
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" @click="btnActiveOnClick('lastMonth')" v-bind:class="{ 'btn-info': btnActive.lastMonth, 'btn-outline-info': btnActive.lastMonth == false }">Bulan Kemarin</button>
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" @click="btnActiveOnClick('year')" v-bind:class="{ 'btn-info': btnActive.year, 'btn-outline-info': btnActive.year == false }">Tahun Ini</button>
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" v-bind:class="{ 'btn-info': btnActive.period, 'btn-outline-info': btnActive.period == false }" @click="btnPeriodeOnClick">Periode</button>
      </div>
      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.employee_name"
            placeholder="Cari Nama Karyawan"
            @keyup="filterByEmployee"></b-form-input>
        </b-input-group>
      </div>
      <div class="col-md-1">
        <b-form-select
          id="input-period"
          v-model="perPage"
          :options="formats"
          @change="perPageNumber"
          placeholder="Per Halaman (Def. 20)"></b-form-select>
      </div>
    </div>

    <div class="row justify-content-between align-items-center mt-5">
      <div class="col-md-6" v-if="onShowPeriod">
        <span class="d-none d-sm-block">
          <b-input-group>
            <b-form-input
              v-model="filter.start_date"
              type="text"
              placeholder="Tanggal Awal"
              autocomplete="off"
              readonly></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.start_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"></b-form-datepicker>
            </b-input-group-append>
            <b-button squared variant="light">s/d</b-button>
            <b-form-input
              v-model="filter.end_date"
              type="text"
              placeholder="Tanggal Akhir"
              autocomplete="off"
              readonly></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.end_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"></b-form-datepicker>
            </b-input-group-append>
            <template #append>
              <b-button squared @click="generalFilter" variant="success">Cari</b-button>
              <b-button squared @click="resetFilter" variant="danger">Reset</b-button>
            </template>
          </b-input-group>
        </span>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>

                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Tanggal Awal"
                  autocomplete="off"
                  readonly></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button squared variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Tanggal Akhir"
                  autocomplete="off"
                  readonly></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"></b-form-datepicker>
                </b-input-group-append>
                <template #prepend>
                  <b-button
                    squared
                    @click="generalFilter"
                    variant="success">Cari</b-button>
                  <b-button
                    squared
                    @click="resetFilter"
                    variant="danger">Reset</b-button>
                </template>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>

      <div class="col-md-6" v-if="onShowPeriod == false"></div>
      <div class="col-md-4 d-flex justify-content-end">
        <button
          class="btn btn-primary mr-1"
          @click="btnExportOnClick">
          Ekspor <i class="fas fa-print px-0 ml-1"></i>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <span
          style="width: 150px; color: #245590 !important"
          class="font-weight-bold">{{ recordData }} Baris Data</span>
      </div>
    </div>

    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      :style="'white-space: nowrap'"
      responsive>

      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(employee_name)="data">
        {{ data.item.employee_name + " (" + data.item.employee_number_id + ")" }}
      </template>

      <template #cell(remuneration_name)="data">
        <div
          v-for="(name, index) in data.item.remuneration_type_name"
          :key="index">
          <li>
            {{
              data.item.remuneration_type_name[index] +
              " (" +
              data.item.quantity[index] +
              " X " +
              parseInt(
                data.item.remuneration_ammount[index]
              ).toLocaleString("id-ID") +
              ")"
            }}
          </li>
        </div>
      </template>

      <template #cell(ammount)="data">
        {{
          "Rp " + parseInt(data.item.ammount).toLocaleString("id-ID")
        }}
      </template>

      <template #cell(payment_method)="data">
        {{ data.item.payment_name }}
      </template>

      <template #cell(paid_date)="data">
        {{ data.item.display_paid_date }}
      </template>

      <template #cell(display_paid_month)="data">
        {{ data.item.display_paid_month }}
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>



    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Laporan Penggajian'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="780px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf">
      <section slot="pdf-content">
        <template>
          <div id="print">
            <div class="d-flex justify-content-center align-items-center mt-3">
              <h5>Laporan Penggajian</h5>
            </div>
            <div class="row">
              <div class="col-12">
                <b-table
                  striped
                  hover
                  :items="items"
                  :fields="fieldsReport"
                  :style="'width: 595pt;'"
                  responsive>

                  <template #cell(no)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template #cell(employee_name)="data">
                    {{ data.item.employee_name + " (" + data.item.employee_number_id + ")" }}
                  </template>

                  <template #cell(remuneration_name)="data">
                    <div
                      v-for="(name, index) in data.item.remuneration_type_name"
                      :key="index">
                      <li>
                        {{
                          data.item.remuneration_type_name[index] +
                          " (" +
                          data.item.quantity[index] +
                          " X " +
                          parseInt(
                            data.item.remuneration_ammount[index]
                          ).toLocaleString("id-ID") +
                          ")"
                        }}
                      </li>
                    </div>
                  </template>

                  <template #cell(ammount)="data">
                    {{
                      "Rp " + parseInt(data.item.ammount).toLocaleString("id-ID")
                    }}
                  </template>

                  <template #cell(payment_method)="data">
                    {{ data.item.payment_name }}
                  </template>

                  <template #cell(paid_date)="data">
                    {{ data.item.display_paid_date }}
                  </template>

                  <template #cell(display_paid_month)="data">
                    {{ data.item.display_paid_month }}
                  </template>
                </b-table>
              </div>
            </div>

          </div>
        </template>
      </section>
    </vue-html2pdf>


    <ModalReport
      :reportType="'payroll-report'"
      :reportName="'Penggajian Karyawan'"
      :dataModal="dataModal"
      :excel="excelData" />
  </div>
</template>

<script>

import XLSX from "xlsx"
import VueHtml2pdf from "vue-html2pdf"
import Card from "@/view/content/Card.vue";
import { debouncer } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ModalReport from "@/component/general/ModalReport.vue"
import moment from 'moment';

export default {
  name: "List",

  components: {
    Card,
    VueHtml2pdf,
    XLSX,
    ModalReport,
  },

  data() {
    return {
      // Table Head
      fields: [
        {
          key: "no",
          label: "No",
          sortable: false,
        },
        {
          key: "employee_name",
          label: "Nama Karyawan",
          sortable: true,
        },
        {
          key: "remuneration_name",
          label: "Remunerasi (qty X Jml)",
          sortable: false,
        },
        {
          key: "ammount",
          label: "Jumlah(Rp)",
          sortable: false,
        },
        {
          key: "payment_method",
          label: "Metode Pembayaran",
          sortable: false,
        },
        {
          key: "paid_date",
          label: "Tanggal Dibayar",
          sortable: true,
        },
        {
          key: "display_paid_month",
          label: "Periode",
          sortable: true,
        },
      ],
      fieldsReport: [
        {
          key: "no",
          label: "No",

        },
        {
          key: "employee_name",
          label: "Nama Karyawan",

        },
        {
          key: "remuneration_name",
          label: "Remunerasi (qty X Jml)",

        },
        {
          key: "ammount",
          label: "Jumlah(Rp)",

        },
        {
          key: "payment_method",
          label: "Metode Pembayaran",

        },
        {
          key: "paid_date",
          label: "Tanggal Dibayar",

        },
        {
          key: "display_paid_month",
          label: "Periode",

        },
      ],
      // Table Items
      items: [],
      dataModal: [],
      excelData: [],
      // Other
      dataLoaded: false,
      manipulateBtn: false,
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      recordData: 0,

      filter: {
        employee_name: "",
        start_date: "",
        end_date: "",
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    };
  },

  methods: {
    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.list();
    },

    perPageNumber() {
      this.list()
    },

    async list() {
      let filterParams = `&employee_name=${this.filter.employee_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "payroll-reports",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      // let responseReport = await module.paginate(
      //   "payroll-no-pagination-reports",
      //   `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      // );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;

      // this.dataModal = responseReport

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
      // this.items = await module.list('payrolls')
      console.log(this.items);
      this.dataLoaded = true;
    },

    async btnExportOnClick() {
      let filterParams = `&employee_name=${this.filter.employee_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let responseReport = await module.paginate(
        "payroll-no-pagination-reports",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      // let pagination = response.meta.pagination;
      // this.totalRows = pagination.total;

      this.dataModal = responseReport.data
      this.excelData = responseReport.data
      console.log('dataModal', this.dataModal)
      // let a, b;
      // b = this.perPage * (this.currentPage - 1) + 1;
      // for (a = 0; a < response.data.length; a++) {
      //   response.data[a].number = b + a;
      // }

      // this.items = response.data;
      // this.items = await module.list('payrolls')
      console.log(this.items);
      this.dataLoaded = true;

      this.$bvModal.show('modal-report')
    },

    resetFilter() {
      this.filter.employee_name = "";
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.btnActiveOnClick(this.lastPeriodType)
      this.list();
    },

    generalFilter() {
      this.list();
    },

    filterByEmployee() {
      debouncer(() => {
        this.filter.start_date = "";
        this.filter.end_date = "";
        if (this.filter.employee_name = "") {
          this.btnActiveOnClick(this.lastPeriodType)
        }
        this.list();
      }, 500)
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("payrolls/" + id);
      // If Deleted
      if (result) {
        this.list();
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        ;

        if (access_right[a] == "7004") {
          this.manipulateBtn = true;
        }
      }
    },

    btnPrintOnClick() {
      this.$htmlToPaper('print')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.items);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Laporan Penggajian.xlsx");
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true
        this.btnActiveOnClick('period')
      } else {
        this.onShowPeriod = false
        this.btnActiveOnClick('period')
      }
    },

    btnActiveOnClick(type) {
      if (type == 'days') {
        // set time
        this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.list()

        // set btn Active
        this.btnActive.days = true
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      } else if (type == 'month') {
        // set time
        this.filter.start_date = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.list()

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = true
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      } else if (type == 'lastMonth') {
        // set time
        this.filter.start_date = moment().subtract(1, 'months').startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().subtract(1, 'months').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')


        // get data
        this.list()

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = true
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      } else if (type == 'year') {
        // set time
        this.filter.start_date = moment().startOf('year').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.list()

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = true
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      } else if (type == 'period') {
        // set time
        this.filter.start_date = ""
        this.filter.end_date = ""

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = true
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Laporan Penggajian" }]);
    // Get Data
    this.list();
    this.setActiveMenu();
  },
};
</script>
